import { useEffect, useState } from 'react';
import API from '../configs/api';

interface IStatusPaymentQuery {
    currentPage?: number,
    limit?: number,
    status?: string
}

const useStatusPayment = (query: IStatusPaymentQuery) => {
    const { currentPage, limit, status }: any = query;
    const [list, setList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalList, setTotalList] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const fetchData = async () => {
        setLoading(true);
        const payload = {
            params: `/${status}`,
            query: { page: currentPage, limit: limit || 10 }
        };
        API.paymentStatus(payload).then((result: any) => {
            if (result.total_data) {
                setTotalList(result.total_data);
            }
            if (result.total_page) {
                setTotalPage(result.total_page);
            }
            setList(result.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchData();
        return () => {
            setList([]);
            setLoading(false);
            setTotalList(0);
        };
    }, [currentPage, limit, status]);

    return { list, loading, totalList, totalPage } as const;
};

export default useStatusPayment;
