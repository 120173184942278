import React, { useState, useEffect } from 'react';
import API from '../../../configs/api';
import BlacklistItem from '../components/BlacklistItem';
import TabLink from '../components/TabLink';
import Pagination from '../../../components/Pagination';
import type { ITabState } from './ListBlacklist';

const HistoryBlacklist: React.FC = () => {
    const [page, setPage] = useState(1);
    const [data, setData] = useState<ITabState>({
        data: [],
        loading: false,
        total_data: 0,
        total_page: 0
    });

    const fetchData = async () => {
        const payload = {
            params: '/history',
            query: {
                page,
                limit: 10
            }
        };

        await API.blacklist(payload).then((result: any) => {
            setData({
                data: result.data,
                loading: false,
                total_data: result.total_data,
                total_page: result.total_page
            });
        });
    };

    useEffect(() => {
        fetchData();
    }, [page]);

    return (
        <>
            <TabLink tabActive="riwayat" />
            <div className="container-fluid">
                {data.data.map((item: any) => (
                    <BlacklistItem key={item.id} data={item} />
                ))}
                <Pagination
                    totalData={data.total_data}
                    currentPage={page}
                    pageCount={data.total_page}
                    onPageChange={(event: any) => {
                        setPage(event.selected + 1);
                    }}
                />
            </div>
        </>
    );
};

export default HistoryBlacklist;
