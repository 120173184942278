import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Drawer from '../../../components/Drawer';
import Icon from '../../../components/Icon';
import DrawerPaymentSuccessItem from './DrawerPaymentSuccessItem';
import { ButtonIcon } from '../../../components/Styled';
import API from '../../../configs/api';
import action from '../../../configs/redux/action';
import { useAppDispatch } from '../../../hooks/useAppReducer';
import noImage from '../../../assets/img/no-image.png';

const DrawerHeader = styled.div`
    position: sticky;
    display: block;
    width: 100%;
    height: auto;
    top: 0;
    padding: .75rem 1.5rem;
    background: #F7F5F5;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, .07);
    z-index: 1;

    button {
        background: #F7F5F5;
        border-color: #D8CFCD;
    }
`;

const DrawerBody = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: .75rem 0;

    #item {
        position: relative;
        padding: 0 1.5rem;
    }
`;

interface IDrawerPaymentSuccess {
    data: any,
    // eslint-disable-next-line no-unused-vars
    drawerHandler: (showDrawer: boolean) => void,
}

const DrawerPaymentSuccess: React.FC<IDrawerPaymentSuccess> = (props) => {
    const dispatch = useAppDispatch();
    const { data, drawerHandler } = props;
    const [staticClose, setStaticClose] = useState(false);
    const [listItem, setListItem] = useState<any>([]);

    const handleClose = () => {
        dispatch(action.drawerDetail({}, false));
        setStaticClose(true);
    };

    const fetchData = () => {
        const payload = {
            query: { external_id: data.external_id }
        };
        API.paymentStatusSuccessDetail(payload).then((result: any) => {
            setListItem(result.data);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Drawer noPadding drawerHandler={drawerHandler} staticClose={staticClose} closeIcon={false} right>
            <DrawerHeader>
                <div className="row align-items-center">
                    <div className="col-8">
                        <div className="row align-items-center">
                            <div className={`col-profile ${listItem.length > 1 ? 'stacking' : ''}`} style={{ padding: 0, marginRight: `-${(listItem.length > 3 ? listItem.length + 1 : listItem.length) * 10}px` }}>
                                {listItem.map((item: any, idx: any) => (
                                    <div key={item.id} className="profile-img" style={{ left: `${idx !== 0 ? `-${idx * 10}` : '0'}px` }}>
                                        {item.pm_profile_image && (
                                            <img style={{ border: '2px solid white' }} height={35} width={35} src={item.pm_profile_image} alt={item.pm_name} />
                                        )}
                                        {!item.pm_profile_image && (
                                            <img style={{ border: '2px solid white' }} src={noImage} alt={item.pm_name} />
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="col-10 ms-2">
                                <p className="mb-0">Total transaksi yang dipilih</p>
                                <p className="mb-0 font-bold font-large">{`${listItem.length} Transaksi`}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 text-end">
                        {/* <Button border rounded textBlack onClick={() => setShowDeleteDialog(true)}>Hapus Semua Transaksi</Button> */}
                    </div>
                    <div className="col-1 text-end">
                        <ButtonIcon onClick={handleClose}>
                            <Icon icon="x" />
                        </ButtonIcon>
                    </div>
                </div>
            </DrawerHeader>
            <DrawerBody>
                {listItem.map((item: any, idx: any) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={idx}>
                        <div id="item">
                            <DrawerPaymentSuccessItem data={item} />
                        </div>
                        <div className="divider" />
                    </React.Fragment>
                ))}
            </DrawerBody>
        </Drawer>
    );
};

export default DrawerPaymentSuccess;
