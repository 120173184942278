import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useCurrency from '../../../helpers/useCurrency';
import TabLink from '../components/TabLink';
import NoData from '../components/NoData';
import useStatusPayment from '../../../hooks/useStatusPayment';
import Table from '../../../components/Table';

const ListPaymentTable = styled.div`
    position: relative;
    display: block;
    width: 100%;
    border-radius: 10px;
`;

const PaymentFailed: React.FC = () => {
    const navigate = useNavigate();
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { list, loading, totalList, totalPage } = useStatusPayment({ status: 'waiting', currentPage, limit: 10 });

    // ** Table Payment List Column
    const columns = [
        {
            name: 'Tgl & Waktu',
            sortable: true,
            minWidth: '135px',
            selector: (row: any) => row.date
        },
        {
            name: 'Nama Proyek',
            sortable: true,
            minWidth: '180px',
            selector: (row: any) => row.project_name,
            conditionalCellStyles: [
                {
                    when: (row: any) => row.project_name === 'Multi Proyek',
                    // style: (row: any) => ({ color: row.project_name === 'Multi Proyek' ? '#EB4600' : 'rgba(0,0,0,0.87);' })
                    style: {
                        color: '#EB4600'
                    }
                }
            ]
        },
        {
            name: 'Nomor Pembayaran',
            sortable: true,
            minWidth: '170px',
            selector: (row: any) => row.external_id
        },
        {
            name: 'Total Pembayaran',
            sortable: true,
            minWidth: '160px',
            style: {
                fontWeight: 600
            },
            selector: (row: any) => `Rp. ${useCurrency(parseInt(row.total, 10))}`
        },
        {
            name: 'Metode Pembayaran',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.bank
        },
        {
            name: 'Waktu Pembayaran',
            sortable: true,
            minWidth: '130px',
            selector: (row: any) => row.expired_at.split(' ')[1]
        }
    ];

    const handleRowClick = useCallback((state: any) => {
        navigate(`/status-pembayaran/${state.external_id}`);
    }, []);

    const itemsPerPage = 10;
    const endOffset = itemOffset + itemsPerPage;

    return (
        <>
            <TabLink tabActive="gagal" />
            <div className="container-fluid px-3 py-2 px-md-4 py-md-3">
                <ListPaymentTable>
                    {!loading && list.length > 0 && (
                        <Table
                            from={itemOffset + 1}
                            to={endOffset > totalList ? totalList : endOffset}
                            columns={columns}
                            data={list}
                            currentPage={currentPage}
                            loading={loading}
                            paginate
                            pageCount={totalPage}
                            totalList={totalList}
                            onRowClicked={handleRowClick}
                            onPageChange={(e: any) => {
                                setCurrentPage(e.selected + 1);
                                const newOffset = (e.selected * itemsPerPage) % totalList;
                                setItemOffset(newOffset);
                            }}
                        />
                    )}
                    {!loading && list.length < 1 && (
                        <NoData message="Tidak ada data pembayaran gagal" />
                    )}
                </ListPaymentTable>
            </div>
        </>
    );
};

export default PaymentFailed;
