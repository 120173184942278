import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DashboardLayout from '../../layout/DashboardLayout';
import CardInformation from './components/CardInformation';
import usePaymentReport from '../../hooks/usePaymentReport';
import SelectInput from '../../components/SelectInput';
import { Button } from '../../components/Styled';
import DialogDateSelector from './components/DialogDateSelector';
import Icon from '../../components/Icon';
import useListProject from '../../hooks/useListProject';
import CardProject from './components/CardProject';

const DatePickerInput = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5rem;
    width: 100%;
    height: 48px;
    border: 1px solid var(--color-card-border);
    border-radius: 10px;
    cursor: pointer;
`;

const statusOptions = [
    {
        title: 'Berhasil',
        value: 'SUCCESS'
    },
    {
        title: 'Ditolak',
        value: 'REJECT'
    },
    {
        title: 'Gagal',
        value: 'FAILED'
    }
];

const PaymentReport: React.FC = () => {
    const [filter, setFilter] = useState({});
    const { data, loading } = usePaymentReport(filter);
    const [listData, setListData] = useState<any>([]);
    const listProject = useListProject();
    // const dataDownload = usePaymentReport(filter, true);
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState('');
    const [project, setProject] = useState('');
    const [selectedStatus, setSelectedStatus] = useState<any>({});
    const [showDateDialog, setShowDateDialog] = useState(false);
    const [projectOptions, setProjectOptions] = useState<any>([]);
    const [selectedProject, setSelectedProject] = useState<any>({});
    const [dateRange, setDateRange] = useState<any>({});
    const loader = useRef(null) as any;

    const handleObserver = useCallback((entries: any) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    useEffect(() => {
        setStatus(selectedStatus.value);
        setPage(1);
    }, [selectedStatus]);

    useEffect(() => {
        setProject(selectedProject.value);
        setPage(1);
    }, [selectedProject]);

    useEffect(() => {
        setFilter({ ...filter, status });
        setPage(1);
    }, [status]);

    useEffect(() => {
        setFilter({ ...filter, project_id: selectedProject?.id });
        setPage(1);
    }, [project]);

    useEffect(() => {
        setFilter({ ...filter, page, limit: 5 });
    }, [page]);

    useEffect(() => {
        if (dateRange.from) {
            setFilter({ ...filter, start_date: moment(dateRange.from).format('YYYY-MM-DD'), end_date: moment(dateRange.to).format('YYYY-MM-DD') });
        }
        setPage(1);
    }, [dateRange]);

    useEffect(() => {
        const opt: any[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < listProject.length; i++) {
            const item = listProject[i];
            opt.push({
                id: item.id,
                title: item.project_name,
                value: item.project_name
            });
        }
        setProjectOptions(opt);
    }, [listProject]);

    useEffect(() => {
        if (data.page === 1) {
            setListData(data.list);
            return;
        }
        setListData([...listData, ...data.list]);
    }, [data]);

    const onDownload = () => {
    };

    return (
        <DashboardLayout activeMenu="laporan-pembayaran">
            <div className="container-fluid p-3 p-md-4">
                <div className="row align-items-center mb-3">
                    <div className="col-12">
                        <div className="row align-items-center justify-content-end">
                            <div className="col-3 text-end">
                                <Button gradient rounded onClick={() => onDownload()}>Download</Button>
                            </div>
                            <div className="col-3">
                                {projectOptions.length > 0 ? (
                                    <SelectInput options={projectOptions} placeholder="Semua Proyek" value={selectedProject.value} onOptionSelected={(item: string) => setSelectedProject(item)} />
                                ) : null}
                                {projectOptions.length < 1 ? (
                                    <SelectInput options={[]} placeholder="Semua Proyek" value={selectedProject.value} onOptionSelected={(item: string) => setSelectedProject(item)} />
                                ) : null}
                            </div>
                            <div className="col-2">
                                <SelectInput options={statusOptions} placeholder="Semua Status" value={selectedStatus.title} onOptionSelected={(item: string) => setSelectedStatus(item)} />
                            </div>
                            <div className="col-3">
                                <DatePickerInput onClick={() => setShowDateDialog(true)}>
                                    <Icon icon="calendar" />
                                    {dateRange.to ? (
                                        <span className="ms-3 font-small color-text">{`${moment(dateRange.from).format('DD/MM/YYYY')} - ${moment(dateRange.to).format('DD/MM/YYYY')}`}</span>
                                    ) : (
                                        <span className="ms-3 font-small color-text">DD/MM/YYYY - DD/MM/YYYY</span>
                                    )}
                                </DatePickerInput>
                            </div>
                        </div>
                    </div>
                </div>
                <CardInformation data={data.card} />
                <p className="my-3 font-bold color-text">Detail Transaksi Keuangan Per-proyek</p>
                {listData.length > 0 && listData.map((item: any, idx: any) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <CardProject key={idx} data={item} />
                ))}
                {loading && <p className="my-2 text-center">Loading...</p>}
                {/* {error && <p>Error!</p>} */}
                <div ref={loader} />
            </div>
            {showDateDialog && (
                <DialogDateSelector
                    dialogHandler={(showDialog: boolean) => setShowDateDialog(showDialog)}
                    onDateSelected={(date: any) => setDateRange(date)}
                />
            )}
        </DashboardLayout>
    );
};

export default PaymentReport;
