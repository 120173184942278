import React from 'react';
import styled from 'styled-components';
import { Button, Label, TextInput } from '../../components/Styled';
import DashboardLayout from '../../layout/DashboardLayout';
import Icon from '../../components/Icon';
import iconBulkImage from '../../assets/icon/icon-bulk-image.svg';

const CompanyId = styled.div`
    position: relative;
    display: block;
    padding: 10px 15px;
    background: #F7F5F5;
    text-align: center;
    color: #959598;
    border: 1px solid #D8CFCD;
    border-radius: 100px;

    span#company-id {
        color: var(--color-base-text-light);
    }

    .action-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;

        @media only screen and (max-width: 768px) {
            position: relative;
        }
    }
`;

const ProfileImage = styled.div`
    position: relative;
    display: block;
    width: 240px;
    text-align: center;

    .profile-img {
        display: flex;
        height: 240px;
        width: 240px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #646464;
        border-radius: 10px;
        cursor: pointer;
    }
`;

interface IContractorDataState {
    name: string,
    company_id: string,
    company_name: string,
    phone: string,
    email: string,
    profile_image: string
}

class ContractorData extends React.Component<any, IContractorDataState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            company_id: '',
            company_name: '',
            phone: '',
            email: '',
            profile_image: ''
        };
    }

    componentDidMount(): void {
        this.setState({ name: 'Agil' });
    }

    render(): React.ReactNode {
        const { name, email, phone, company_id, company_name, profile_image } = this.state;
        return (
            <DashboardLayout activeMenu="data-kontraktor">
                <div className="container-fluid p-3 p-md-4 color-text">
                    <div className="row mb-3">
                        <div className="col-7">
                            <h5 className="font-bold">Data Kontraktor</h5>
                            <p>Silakan cek kembali data kontraktor kamu, jika terdapat data tidak sesuai Hubungi Kanggo</p>
                        </div>
                        <div className="col-3">
                            <CompanyId>
                                <span>Nomor ID Perusahaan : </span>
                                <span id="company-id" className="font-bold">{company_id}</span>
                            </CompanyId>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <Label bold htmlFor="name">Nama Kontraktor</Label>
                            <TextInput className="mb-3" block border id="name" disabled type="text" placeholder="Nama Kontraktor" value={name} />
                            <Label bold htmlFor="company_name">Nama Perusahaan</Label>
                            <TextInput className="mb-3" block border id="company_name" disabled type="text" placeholder="Nama Perusahaan" value={company_name} />
                            <Label bold htmlFor="phone">Nomor Telepon</Label>
                            <TextInput className="mb-3" block border id="phone" disabled type="text" placeholder="Nomor Telepon" value={phone} />
                            <Label bold htmlFor="email">Email Kontraktor</Label>
                            <TextInput className="mb-3" block border id="email" disabled type="text" placeholder="Email Kontraktor" value={email} />
                        </div>
                        <div className="col-profile">
                            <ProfileImage>
                                {!profile_image && (
                                    <div className="profile-img border">
                                        <Icon custom icon={iconBulkImage} />
                                        <p className="my-2 font-bold color-text">Foto Kontraktor</p>
                                        <p className="my-1 font-small">
                                            Drag and drop gambar atau
                                            <span className="font-bold" style={{ color: 'var(--color-base-primary-light)' }}> Upload</span>
                                        </p>
                                        <span className="d-block font-small">Rekomendasi resolusi 1000x1000, Maksimal 10 MB</span>
                                    </div>
                                )}
                                {profile_image && (
                                    <>
                                        <div className="profile-img">
                                            <img src={profile_image} alt={name} />
                                        </div>
                                        <p id="change-photo" className="my-2 font-bold">Ganti foto</p>
                                        <span className="d-block font-small">Rekomendasi resolusi 1000x1000, Maksimal 10 MB</span>
                                    </>
                                )}
                            </ProfileImage>
                        </div>
                        <div className="col" />
                    </div>
                    <div className="action-wrapper p-3 p-md-4">
                        <div className="row">
                            <div className="col-10" />
                            <div className="col-2">
                                <Button block border rounded textBlack>Keluar</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default ContractorData;
