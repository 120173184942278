import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Label, TextInput } from '../../components/Styled';
import DashboardLayout from '../../layout/DashboardLayout';
import action from '../../configs/redux/action';
import Icon from '../../components/Icon';
import iconBulkImage from '../../assets/icon/icon-bulk-image.svg';
import API from '../../configs/api';
import BackButton from '../../components/BackButton';

const NumberId = styled.div`
    position: relative;
    display: block;
    padding: 10px 15px;
    background: #F7F5F5;
    text-align: center;
    color: #959598;
    border: 1px solid #D8CFCD;
    border-radius: 100px;

    span#company-id {
        color: var(--color-base-text-light);
    }

    .action-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;

        @media only screen and (max-width: 768px) {
            position: relative;
        }
    }
`;

const ProfileImage = styled.div`
    position: relative;
    display: block;
    width: 240px;
    text-align: center;

    .profile-img {
        display: flex;
        height: 240px;
        width: 240px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #646464;
        border-radius: 10px;
        cursor: pointer;

        img {
            width: 100%;
            border-radius: 20px;
        }

    }

    #change-photo {
        position: relative;
        display: block;
        color: var(--color-base-primary-light);
        cursor: pointer;
    }
`;

interface IProfileState {
    name: string,
    phone: string,
    email: string,
    image: string
}

interface IProfile {
    dispatch: any,
    profile_id: string,
    profile_name: string,
    profile_phone: string,
    profile_email: string,
    profile_image: string
}

class Profile extends React.Component<IProfile, IProfileState> {
    constructor(props: IProfile) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            image: ''
        };
    }

    componentDidMount(): void {
        const { profile_name, profile_phone, profile_email, profile_image } = this.props;
        this.setState({ name: profile_name, phone: profile_phone, email: profile_email, image: profile_image });
    }

    handleInput = (event: { target: { id: string, value: any } }) => {
        const newState = { [event.target.id]: event.target.value } as Pick<IProfileState, keyof IProfileState>;
        this.setState(newState);
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { name, phone, email } = this.state;
        dispatch(action.showLoading());
        const payload = {
            body: {
                name,
                phone,
                email
            }
        };
        API.updateProfile(payload).then((result: any) => {
            dispatch(action.getProfile());
            dispatch(action.showToast('success', result.message));
        }).catch((err) => {
            dispatch(action.hideLoading());
            dispatch(action.showToast('error', err.message));
        });
    };

    render(): React.ReactNode {
        const { profile_id } = this.props;
        const { name, email, phone, image } = this.state;
        return (
            <DashboardLayout activeMenu="profile">
                <div className="container-fluid p-3 p-md-4 color-text">
                    <div className="row mb-3">
                        <div className="col-9">
                            <h5 className="font-bold">Data Kontraktor</h5>
                            <p>
                                Silakan cek kembali data kontraktor kamu, jika terdapat data tidak sesuai
                                <a href="/bantuan"> Hubungi Kanggo</a>
                            </p>
                        </div>
                        <div className="col-3">
                            <NumberId>
                                <span>Nomor ID : </span>
                                <span id="company-id" className="font-bold">{profile_id}</span>
                            </NumberId>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <Label bold htmlFor="name">Nama Kontraktor</Label>
                            <TextInput className="mb-3" block border id="name" type="text" placeholder="Nama Kontraktor" onChange={this.handleInput} value={name} />
                            <Label bold htmlFor="phone">Nomor Telepon</Label>
                            <TextInput className="mb-3" block border id="phone" type="text" placeholder="Nomor Telepon" onChange={this.handleInput} value={phone} />
                            <Label bold htmlFor="email">Email Kontraktor</Label>
                            <TextInput className="mb-3" block border id="email" type="text" placeholder="Email Kontraktor" onChange={this.handleInput} value={email} />
                        </div>
                        <div className="col-profile">
                            <ProfileImage>
                                {!image && (
                                    <div className="profile-img border">
                                        <Icon custom icon={iconBulkImage} />
                                        <p className="my-2 font-bold" style={{ color: 'var(--color-black)' }}>Foto Profile</p>
                                        <p className="my-1 font-small">
                                            Drag and drop gambar atau
                                            <span className="font-bold" style={{ color: 'var(--color-base-primary-light)' }}> Upload</span>
                                        </p>
                                        <span className="d-block font-small">Rekomendasi resolusi 1000x1000, Maksimal 10 MB</span>
                                    </div>
                                )}
                                {image && (
                                    <>
                                        <div className="profile-img">
                                            <img src={image} alt={name} />
                                        </div>
                                        <p id="change-photo" className="my-2 font-bold">Ganti foto</p>
                                        <span className="d-block font-small">Rekomendasi resolusi 1000x1000, Maksimal 10 MB</span>
                                    </>
                                )}
                            </ProfileImage>
                        </div>
                        <div className="col" />
                    </div>
                    {/* <div className="row mb-3">
                        <div className="col-9">
                            <h5 className="font-bold">Tugas dan Hak Akses</h5>
                            <p>Hak akses dapat diberikan l ebih dari satu tugas dan fungsinya</p>
                        </div>
                        <div className="col" />
                    </div> */}
                    <div className="action-wrapper mt-2 mt-md-3 p-3 p-md-4">
                        <div className="row">
                            <div className="col-4 col-md-8" />
                            <div className="col-4 col-md-2">
                                <BackButton />
                            </div>
                            <div className="col-4 col-md-2">
                                <Button block rounded gradient onClick={this.handleSubmit}>Simpan</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        profile_id: state.userReducer.profile.id,
        profile_name: state.userReducer.profile.name,
        profile_phone: state.userReducer.profile.phone,
        profile_email: state.userReducer.profile.email,
        profile_image: state.userReducer.profile.profile_image
    };
};

export default connect(mapStateToProps)(Profile);
