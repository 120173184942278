import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import moment from 'moment';
// import DataTable from 'react-data-table-component';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import RowNumber from '../../components/RowNumber';
import SelectInput from '../../components/SelectInput';
import { TextInput } from '../../components/Styled';
// import API from '../../configs/api';
import DashboardLayout from '../../layout/DashboardLayout';
import useContractorProject from '../../hooks/useContractorProject';
import DrawerDetailProject from './components/DrawerDetailProject';
import BadgeStatus from './components/BadgeStatus';
import Table from '../../components/Table';

const ListProjectTable = styled.div`
    position: relative;
    display: block;
    width: 100%;
`;

type InputProps = React.HTMLProps<HTMLInputElement>;

const projectStatusOption = [
    {
        title: 'Active',
        value: 'ACTIVE'
    },
    {
        title: 'Pending',
        value: 'PENDING'
    },
    {
        title: 'Finish',
        value: 'FINISH'
    }
];

const ContractorProject: React.FC = () => {
    const [listDataFilter, setListDataFilter] = useState<any>({
        page: 1
    });

    const [data, setData] = useState<any[]>([]);
    const [, setPending] = useState(true);
    const [showDetailDrawer, setShowDrawerDetail] = useState(false);
    const [selectedProject, setSelectedProject] = useState<any>({});

    const [date, setDate] = useState();
    // const [projectStatusOption, setProjectStatusOption] = useState<any[]>([]);

    const [projectStatus, setProjectStatus] = useState('');
    const [selectedProjectStatus, setSelectedProjectStatus] = useState<any>({});

    const [projectCodeOption, setProjectCodeOption] = useState<any[]>([]);
    const [projectCode, setProjectCode] = useState('');
    const [selectedProjectCode, setSelectedProjectCode] = useState<any>({});
    const [search, setSearch] = useState('');
    const [offset, setOffset] = useState(0);
    const { listData, totalData, totalPage, loading } = useContractorProject(listDataFilter);

    const columns = [
        {
            name: 'Tanggal Dibuat',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => moment(row.created_at).format('YYYY-MM-DD')
        },
        {
            name: 'Kode Proyek',
            sortable: true,
            minWidth: '50px',
            selector: (row: any) => row.project_code
        },
        {
            name: 'Nama Proyek',
            sortable: true,
            // minWidth: '170px',
            selector: (row: any) => row.project_name
        },
        {
            name: 'Client',
            sortable: true,
            // minWidth: '100px',
            selector: (row: any) => row.client_name
        },
        {
            name: 'Nama PM',
            sortable: true,
            // minWidth: '130px',
            selector: (row: any) => row.pm_name
        },
        {
            name: 'Anggota',
            sortable: true,
            // minWidth: '130px',
            selector: (row: any) => <RowNumber total={row.total_workers} tooltips={`${row.total_workers} Anggota`} />
        },
        {
            name: 'Status',
            sortable: true,
            // minWidth: '80px',
            selector: (row: any) => <BadgeStatus status_project={row.status_project} />
        }
    ];

    useEffect(() => {
        if (listData.length < 1) {
            setPending(true);
        }
        setPending(false);
        setData(listData);
    }, [listData]);

    useEffect(() => {
        const codes = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data.length; i++) {
            const obj = {
                title: data[i].project_code,
                value: data[i].project_code
            };
            codes.push(obj);
            setProjectCodeOption(codes);
        }
    }, [data]);

    useEffect(() => {
        setProjectCode(selectedProjectCode.title);
        setListDataFilter({ ...listDataFilter, project_code: selectedProjectCode.value });
    }, [selectedProjectCode]);

    useEffect(() => {
        setProjectStatus(selectedProjectStatus.title);
        setListDataFilter({ ...listDataFilter, status: selectedProjectStatus.value });
    }, [selectedProjectStatus]);

    // eslint-disable-next-line react/no-unstable-nested-components
    const DatePicker = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <TextInput ref={ref} placeholder={moment(new Date()).format('DD-MM-YYYY')} style={{ textTransform: 'uppercase', width: '100%' }} type="text" border value={props.value} onClick={props.onClick} onChange={props.onChange} />
    ));

    useEffect(() => {
        if (date) {
            const day = moment(date).format('YYYY-MM-DD');
            setListDataFilter({ ...listDataFilter, start_date: day, end_date: day });
        }
    }, [date]);

    const handleRowClick = useCallback((state: any) => {
        setSelectedProject(state);
        setShowDrawerDetail(true);
    }, []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setListDataFilter({ ...listDataFilter, search: e.target.value });
    };

    return (
        <DashboardLayout activeMenu="proyek-kontraktor">
            <div className="container-fluid p-3 p-md-4">
                <div className="row align-items-center mb-3">
                    <div className="col-4">
                        <p className="m-0">
                            <span className="font-bold me-2">{totalData}</span>
                            Proyek Kontraktor dalam list
                        </p>
                    </div>
                    <div className="col-8">
                        <div className="row justify-content-end">
                            <div className="col-3">
                                <ReactDatePicker dateFormat="dd-MM-yyyy" selected={date} onChange={(newDate: any) => setDate(newDate)} customInput={<DatePicker />} />
                            </div>
                            <div className="col-3">
                                {projectCodeOption.length > 0 && (
                                    <SelectInput options={projectCodeOption} placeholder="Kode Proyek" value={projectCode} onOptionSelected={(item: string) => setSelectedProjectCode(item)} />
                                )}
                                {projectCodeOption.length < 1 && (
                                    <SelectInput options={[]} placeholder="Kode Proyek" />
                                )}
                            </div>
                            <div className="col-3">
                                <TextInput onChange={(e) => handleSearch(e)} value={search} block border placeholder="Cari..." />
                            </div>
                            <div className="col-3">
                                {projectCodeOption.length > 0 && (
                                    <SelectInput options={projectStatusOption} placeholder="Status" value={projectStatus} onOptionSelected={(item: string) => setSelectedProjectStatus(item)} />
                                )}
                                {projectCodeOption.length < 1 && (
                                    <SelectInput options={[]} placeholder="Status" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ListProjectTable>
                    <Table
                        from={offset + 1}
                        to={offset + 10}
                        paginate
                        columns={columns}
                        pageCount={totalPage}
                        totalList={totalData}
                        currentPage={listDataFilter.page}
                        loading={loading}
                        data={data}
                        onPageChange={(e: any) => {
                            setListDataFilter({ ...listDataFilter, page: e.selected + 1 });
                            setOffset((e.selected * 10) % totalData);
                        }}
                        onRowClicked={handleRowClick}
                    />
                </ListProjectTable>
            </div>
            {showDetailDrawer && (
                <DrawerDetailProject project={selectedProject} drawerHandler={(showDrawer: boolean) => setShowDrawerDetail(showDrawer)} />
            )}
        </DashboardLayout>
    );
};

export default ContractorProject;
