import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { TextInput } from '../../../components/Styled';
// import iconSearch from '../../../assets/icon/icon-search.svg';
import useCurrency from '../../../helpers/useCurrency';
import RowProfileCard from '../../../components/RowProfileCard';
import API from '../../../configs/api';
import noDataImg from '../../../assets/img/illustrasi-no-data.png';
import SelectInput from '../../../components/SelectInput';
import Table from '../../../components/Table';

const ProjectActiveBar = styled.div`
    position: relative;
    display: block;
    width: 100%;
    background-color: var(--color-semi-white);

    a {
        color: var(--color-base-primary-light);
        text-decoration: none;
    }
`;

const BackgroundWrapper = styled('div').attrs((props) => ({
    className: props.className
}))`
    background-color: var(--color-base-bg-light);
`;

const NoDataWrapper = styled.div`
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 100px;

    img {
        height: 100%;
    }
`;

const ProjectActiveTable = styled.div`
    position: relative;
    display: block;
    background-color: var(--color-base-bg-light);
    width: 100%;
`;

const ListActiveProject = () => {
    const [data, setData] = useState<any>([]);
    const [isPending, setPending] = useState(true);
    const [date, setDate] = useState(new Date());
    const [projectCodeOption, setProjectCodeOption] = useState<any[]>([]);
    const [projectCode, setProjectCode] = useState('');
    const [selectedProjectCode, setSelectedProjectCode] = useState<any>({});
    const [totalPage, setTotalPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [page, setPage] = useState(1);

    const fetchData = () => {
        const payload = {
            query: {
                page,
                limit: 10
            }
        };

        API.projectActiveRequest(payload).then((result: any) => {
            setPending(false);
            setData(result.data);
            setTotalPage(result.total_page);
            setTotalData(result.total_data);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    // ** Table Project Active Column
    const columns = [
        {
            name: 'Tanggal Dibuat',
            sortable: true,
            minWidth: '135px',
            selector: (row: any) => row.created_at
        },
        {
            name: 'Kode Proyek',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.project_code
        },
        {
            name: 'Nama Proyek',
            sortable: true,
            minWidth: '150px',
            selector: (row: any) => row.project_name
        },
        {
            name: 'Client',
            sortable: true,
            minWidth: '120px',
            selector: (row: any) => row.client_name
        },
        {
            name: 'Nama PM',
            allowOverflow: true,
            minWidth: '150px',
            style: {
                color: '#D9272D'
            },
            cell: (row: any) => <RowProfileCard data={row} />
        },
        {
            name: 'Anggota',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.total_workers
        },
        {
            name: 'Total Pengajuan',
            sortable: true,
            minWidth: '150px',
            style: {
                fontWeight: '600'
            },
            selector: (row: any) => `Rp. ${useCurrency(row.total_request)}`
        }
    ];

    useEffect(() => {
        const codes = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data.length; i++) {
            const obj = {
                title: data[i].project_code,
                value: data[i].project_code
            };
            codes.push(obj);
            setProjectCodeOption(codes);
        }
    }, [data]);

    useEffect(() => {
        setProjectCode(selectedProjectCode.title);
    }, [selectedProjectCode]);

    return (
        <>
            <BackgroundWrapper className="container-fluid px-3 py-2 px-md-4 py-md-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-4">
                        <span className="font-bold color-text">Proyek Aktif</span>
                    </div>
                    <div className="col-8">
                        <div className="row justify-content-end">
                            <div className="col-3">
                                <TextInput style={{ textTransform: 'uppercase', backgroundColor: 'var(--color-input)' }} type="date" border value={moment(new Date(date)).format('DD/MM/YYYY')} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDate(new Date(e.target.value))} />
                            </div>
                            <div className="col-3">
                                {projectCodeOption.length > 0 && (
                                    <SelectInput style={{ backgroundColor: 'var(--color-input)' }} options={projectCodeOption} placeholder="Kode Proyek" value={projectCode} onOptionSelected={(item: string) => setSelectedProjectCode(item)} />
                                )}
                                {projectCodeOption.length < 1 && (
                                    <SelectInput options={[]} placeholder="Kode Proyek" />
                                )}
                            </div>
                            <div className="col-5">
                                <TextInput style={{ backgroundColor: 'var(--color-input)' }} block border placeholder="Cari..." />
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundWrapper>
            <ProjectActiveBar>
                <div className="container-fluid px-3 py-1 px-md-4 py-md-2">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-6">
                            <span className="font-small font-bold color-text">{data.length}</span>
                            <span className="font-small color-text"> Proyek Aktif Kamu</span>
                        </div>
                        <div className="col-6 text-end">
                            <Link to="/proyek-kontraktor" className="font-small font-bold">Lihat Semua</Link>
                        </div>
                    </div>
                </div>
            </ProjectActiveBar>
            <BackgroundWrapper className="container-fluid px-3 py-2 px-md-4 py-md-3">
                {data.length > 0 && (
                    <ProjectActiveTable>
                        <Table
                            paginate
                            columns={columns}
                            pageCount={totalPage}
                            totalList={totalData}
                            currentPage={page}
                            loading={isPending}
                            data={data}
                            onPageChange={(e: any) => {
                                setPage(e.selected + 1);
                            }}
                        />
                    </ProjectActiveTable>
                )}
                {data.length < 1 && (
                    <NoDataWrapper>
                        <img src={noDataImg} alt="No Data" />
                        <p className="mb-0 mt-2 font-bold color-text">Tidak ada data proyek aktif</p>
                    </NoDataWrapper>
                )}
            </BackgroundWrapper>
        </>
    );
};

export default ListActiveProject;
