/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import DashboardLayout from '../../layout/DashboardLayout';
import { Button, Label, TextInput, TextInputGroup } from '../../components/Styled';
import banner from '../../assets/img/banner-kanggo.png';
import iconEyeClose from '../../assets/icon/icon-eye-close.svg';
import iconEyeOpen from '../../assets/icon/icon-eye-open.svg';
import Icon from '../../components/Icon';
import action from '../../configs/redux/action';
import API from '../../configs/api';
import SendEmailDialog from '../../components/SendEmailDialog';
import BackButton from '../../components/BackButton';

const PinWrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    color: var(--color-base-text-light);
    #banner{
        height: 100%;
        img {
            height: 100%;
        }

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .action-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;

        @media only screen and (max-width: 768px) {
            position: relative;
        }
    }
`;

interface IPinState {
    old_pin: string,
    new_pin: string,
    show_old_pin: boolean,
    show_new_pin: boolean,
    forgot_pin: boolean
}

interface IPinProps {
    dispatch: any,
    email: string
}

class Pin extends React.Component<IPinProps, IPinState> {
    constructor(props: IPinProps) {
        super(props);
        this.state = {
            old_pin: '',
            new_pin: '',
            show_old_pin: false,
            show_new_pin: false,
            forgot_pin: false
        };
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput = (event: { target: { id: string, value: any } }) => {
        const newState = { [event.target.id]: event.target.value } as Pick<IPinState, keyof IPinState>;
        this.setState(newState);
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { old_pin, new_pin } = this.state;
        dispatch(action.showLoading());
        const payload = {
            body: {
                old_pin,
                new_pin
            }
        };
        API.changePin(payload).then((response: any) => {
            dispatch(action.hideLoading());
            dispatch(action.showToast('success', response.message));
        }).catch((err) => {
            dispatch(action.hideLoading());
            dispatch(action.showToast('error', err.message));
        });
    };

    render(): React.ReactNode {
        const { email } = this.props;
        const { old_pin, new_pin, show_old_pin, show_new_pin, forgot_pin } = this.state;
        return (
            <DashboardLayout activeMenu="pin">
                <PinWrapper>
                    <div id="banner">
                        <img src={banner} alt="PT Tenaga Kanggo Indonesia" />
                    </div>
                    <div className="container-fluid p-3 p-md-4">
                        <div className="row mb-3">
                            <div className="col-12">
                                <h5 className="font-bold">Ubah PIN</h5>
                                <p>Silakan masukkan PIN saat ini dan masukkan PIN baru kamu</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5">
                                <Label bold htmlFor="old_pin">Masukkan PIN Lama</Label>
                                <TextInputGroup style={{ marginBottom: 30 }} border>
                                    <TextInput block border id="old_pin" type={show_old_pin ? 'text' : 'password'} placeholder="********" onChange={this.handleInput} value={old_pin} />
                                    <div id="input-icon" role="button" onClick={() => this.setState({ show_old_pin: !show_old_pin })}>
                                        <div style={{ display: show_old_pin ? 'none' : 'block' }}>
                                            <Icon custom icon={iconEyeClose} />
                                        </div>
                                        <div style={{ display: show_old_pin ? 'block' : 'none' }}>
                                            <Icon custom icon={iconEyeOpen} />
                                        </div>
                                    </div>
                                </TextInputGroup>
                                <Label bold htmlFor="new_pin">Masukkan PIN Baru</Label>
                                <TextInputGroup style={{ marginBottom: 30 }} border>
                                    <TextInput block border id="new_pin" type={show_new_pin ? 'text' : 'password'} placeholder="********" onChange={this.handleInput} value={new_pin} />
                                    <div id="input-icon" role="button" onClick={() => this.setState({ show_new_pin: !show_new_pin })}>
                                        <div style={{ display: show_new_pin ? 'none' : 'block' }}>
                                            <Icon custom icon={iconEyeClose} />
                                        </div>
                                        <div style={{ display: show_new_pin ? 'block' : 'none' }}>
                                            <Icon custom icon={iconEyeOpen} />
                                        </div>
                                    </div>
                                </TextInputGroup>
                            </div>
                            <div className="col-12">
                                <div className="d-inline-block" style={{ cursor: 'pointer' }} onClick={() => this.setState({ forgot_pin: true })}>
                                    <span className="text-primary font-bold">Lupa Pin?</span>
                                </div>
                            </div>
                        </div>
                        <div className="action-wrapper p-3 p-md-4">
                            <div className="row">
                                <div className="col-4 col-md-8" />
                                <div className="col-4 col-md-2">
                                    <BackButton />
                                </div>
                                <div className="col-4 col-md-2">
                                    <Button block rounded gradient onClick={this.handleSubmit}>Simpan</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </PinWrapper>
                {forgot_pin && (
                    <SendEmailDialog message={`Link perubahan PIN sudah dikirim ke email ${email}. Silakan lakukan perubahan PIN`} dialogHandler={(showDialog: boolean) => this.setState({ forgot_pin: showDialog })} />
                )}
            </DashboardLayout>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        email: state.userReducer.profile.email
    };
};

export default connect(mapStateToProps)(Pin);
