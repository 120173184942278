import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '../../../components/Dialog';
import RowProfileCard from '../../../components/RowProfileCard';
import { Badge, TextInput } from '../../../components/Styled';
import API from '../../../configs/api';
import SelectInput from '../../../components/SelectInput';
import useText from '../../../helpers/useText';
import Table from '../../../components/Table';

const DialogHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
`;

interface IDialogWorker {
    role: string,
    // eslint-disable-next-line no-unused-vars
    dialogHandler: (visible: boolean) => void
}

const statusOptions = [
    {
        title: 'Aktif',
        value: 'ACTIVE'
    },
    {
        title: 'Nonaktif',
        value: 'NONACTIVE'
    }
];

const roleOptions = [
    {
        title: 'PM',
        value: 'PM'
    },
    {
        title: 'Pelaksana',
        value: 'PELAKSANA'
    },
    {
        title: 'Mandor',
        value: 'MANDOR'
    }
];

const blacklistOptions = [
    {
        title: 'Blacklist',
        value: 'TRUE'
    },
    {
        title: 'Non Blacklist',
        value: 'FALSE'
    }
];

const DialogWorker: React.FC<IDialogWorker> = (props) => {
    const { role, dialogHandler } = props;
    const [data, setData] = useState<any>({});
    const [isPending, setPending] = useState(true);
    const [search, setSearch] = useState('');
    const [selectedStatus, setSelectedStatus] = useState<any>({});
    const [selectedRole, setSelectedRole] = useState<any>({});
    const [selectedBlacklist, setSelectedBlacklist] = useState<any>({});
    const [totalList, setTotalList] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemOffset, setItemOffset] = useState(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const fetchData = () => {
        let initRole = role;
        let initStatus = '';
        let initBlacklist = '';

        if (Object.keys(selectedRole).length > 0) {
            initRole = selectedRole.value;
        }

        if (Object.keys(selectedStatus).length > 0) {
            initStatus = selectedStatus.value;
        }

        if (Object.keys(selectedBlacklist).length > 0) {
            initBlacklist = selectedBlacklist.value;
        }

        const payload = {
            query: { role: initRole, search, blacklist: initBlacklist, status: initStatus, page: currentPage }
        };
        API.listWorker(payload).then((result: any) => {
            setData(result.data);
            setTotalList(result.total_data);
            setTotalPage(result.total_page);
            setPending(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, [search, selectedRole, selectedStatus, selectedBlacklist, currentPage]);

    // ** Table List Worker PM
    const columnsPM = [
        {
            name: 'ID PM',
            sortable: true,
            width: '200px',
            selector: (row: any) => row.user_id
        },
        {
            name: 'Nama PM',
            allowOverflow: true,
            minWidth: '200px',
            style: {
                color: '#D9272D',
                fontWeight: '800'
            },
            cell: (row: any) => <RowProfileCard data={row} />
        },
        {
            name: 'Proyek Aktif',
            sortable: true,
            width: '200px',
            selector: (row: any) => row.project_active
        },
        {
            name: 'Status PM',
            sortable: true,
            width: '200px',
            selector: (row: any) => (row.is_active === 1 ? <Badge rounded success>Aktif</Badge> : <Badge rounded error>Tidak Aktif</Badge>)
        }
    ];

    const columnsMandor = [
        {
            name: 'ID Mandor',
            sortable: true,
            width: '200px',
            selector: (row: any) => row.user_id
        },
        {
            name: 'Nama Mandor',
            allowOverflow: true,
            minWidth: '400px',
            style: {
                color: '#D9272D',
                fontWeight: '800'
            },
            cell: (row: any) => <RowProfileCard data={row} />
        }
    ];

    const itemsPerPage = 5;
    const endOffset = itemOffset + itemsPerPage;

    return (
        <Dialog fullHeight closeBtn dialogHandler={dialogHandler}>
            <DialogHeader className="mb-md-5 mb-4 color-text">
                {role === 'PM' && (<p className="m-0 font-bold">Jumlah PM Aktif</p>)}
                {role === 'PELAKSANA' && (<p className="m-0 font-bold">Jumlah Pelaksana/Logistik Aktif</p>)}
                {role === 'MANDOR' && (<p className="m-0 font-bold">Jumlah</p>)}
                <span className="font-bold">{data.total_workers}</span>
                {role === 'PM' && (<span> PM Aktif</span>)}
                {role === 'PELAKSANA' && (<span> Pelaksana/Logistik Aktif</span>)}
                {role === 'MANDOR' && (<span> Mandor</span>)}
            </DialogHeader>

            <div className="row gx-3 justify-content-end">
                <div className="col-6">
                    <TextInput className="mb-3" block border id="search" type="text" placeholder={`Cari ID atau Nama ${useText.renderSwitch(role)}`} onChange={(event) => handleChange(event)} value={search} />
                </div>
                {role === 'PELAKSANA' && (
                    <div className="col-3 col-md-2">
                        <SelectInput options={roleOptions} placeholder="Pilih" value={selectedRole.title} onOptionSelected={(item: any) => setSelectedRole(item)} />
                    </div>
                )}
                {(role === 'PM' || role === 'PELAKSANA') && (
                    <div className="col-3 col-md-2">
                        <SelectInput options={statusOptions} placeholder="Pilih" value={selectedStatus.title} onOptionSelected={(item: any) => setSelectedStatus(item)} />
                    </div>
                )}
                {role === 'MANDOR' && (
                    <div className="col-4">
                        <SelectInput options={blacklistOptions} placeholder="Pilih" value={selectedBlacklist.title} onOptionSelected={(item: any) => setSelectedBlacklist(item)} />
                    </div>
                )}
            </div>

            {data.total_workers > 0 && (
                <div style={{ height: '65%' }}>
                    <Table
                        marginTop="-6em"
                        paginate
                        columns={role === 'PM' || role === 'PELAKSANA' ? columnsPM : columnsMandor}
                        from={itemOffset + 1}
                        to={endOffset > totalList ? totalList : endOffset}
                        totalList={totalList}
                        pageCount={totalPage}
                        currentPage={currentPage}
                        loading={isPending}
                        data={data.workers}
                        onPageChange={(e: any) => {
                            setCurrentPage(e.selected + 1);
                            const newOffset = (e.selected * itemsPerPage) % totalList;
                            setItemOffset(newOffset);
                        }}
                    />
                </div>
            )}
        </Dialog>
    );
};

export default DialogWorker;
