import { useState, useEffect } from 'react';
import API from '../configs/api';

const useContractorProject = (filter: any) => {
    const [listData, setListData] = useState<any[]>([]);
    const [totalData, setTotalData] = useState<number>(0);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const { status, start_date, end_date, page, project_code, search } = filter;

    useEffect(() => {
        const handleListData = (data: any[], total_data: number, total_page: number) => {
            setListData(data);
            setTotalData(total_data);
            setTotalPage(total_page);
        };

        const query: any = {};
        if (status) {
            query.status_project = status;
        }
        if (project_code) {
            query.project_code = project_code;
        }
        if (start_date) {
            query.start_date = start_date;
        }
        if (end_date) {
            query.end_date = end_date;
        }
        if (search) {
            query.search = search;
        }

        query.limit = 10;
        query.page = page;

        setLoading(true);
        const payload = {
            query
        };
        API.projectContractor(payload).then((result: any) => {
            handleListData(result.data, result.total_data, result.total_page);
            setLoading(false);
        }).catch(() => {
            handleListData([], 0, 0);
        });
        return () => {
            handleListData([], 0, 0);
        };
    }, [filter, page]);

    return { listData, totalData, loading, totalPage };
};

export default useContractorProject;
