import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import RowProfileCard from '../../../components/RowProfileCard';
import { Badge, Button } from '../../../components/Styled';
import useCurrency from '../../../helpers/useCurrency';
import Icon from '../../../components/Icon';
import DrawerPaymentAction from '../components/DrawerPaymentAction';
import TabLink from '../components/TabLink';
import NoData from '../components/NoData';
import noImage from '../../../assets/img/no-image.png';
import RowNumber from '../../../components/RowNumber';
import Table from '../../../components/Table';
import useStatusPayment from '../../../hooks/useStatusPayment';

const ListPaymentTable = styled.div`
    position: relative;
    display: block;
    width: 100%;
`;

const FloatingPaymentSelected = styled.div <{ visible?: boolean }>`
    position: fixed;
    display: block;
    width: 100%;
    max-width: calc(100vw - 30%);
    height: auto;
    padding: 10px 0;
    color: var(--color-white);
    background: #2F2F2F;
    border-radius: 100px;
    right: 5%;
    bottom: 10%;
    opacity: 0%;
    transition: opacity .3s ease;
    z-index: 99;

    ${(props) => (props.visible ? 'opacity: 100%;' : '')}
`;

const PaymentPending = () => {
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [showDrawerPayment, setShowDrawerPayment] = useState(false);

    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { list, loading, totalList, totalPage } = useStatusPayment({ status: 'pending', currentPage, limit: 10 });

    const itemsPerPage = 10;
    const endOffset = itemOffset + itemsPerPage;

    const handleRowSelected = useCallback((state: any) => {
        setSelectedRows(state.selectedRows);
    }, []);

    const handleRowClick = useCallback((state: any) => {
        setSelectedRows([state]);
        setShowDrawerPayment(true);
    }, []);

    const handleRowDelete = () => {
        setToggleCleared(!toggleCleared);
        setSelectedRows([]);
    };

    // ** Table Payment List Column
    const columns = [
        {
            name: 'Tgl & Waktu',
            sortable: true,
            minWidth: '100px',
            selector: (row: any) => row.created_at
        },
        {
            name: 'Kode Proyek',
            sortable: true,
            selector: (row: any) => row.project_code
        },
        {
            name: 'Nama Proyek',
            sortable: true,
            selector: (row: any) => row.project_name
        },
        {
            name: 'Client',
            sortable: true,
            selector: (row: any) => row.client
        },
        {
            name: 'Nama PM',
            allowOverflow: true,
            style: {
                color: '#EB4600'
            },
            cell: (row: any) => <RowProfileCard data={row.pm} />
        },
        {
            name: 'Anggota',
            sortable: true,
            minWidth: '80px',
            selector: (row: any) => <RowNumber total={row.total_workers} tooltips={`${row.total_workers} Anggota`} />
        },
        {
            name: 'Total Pembayaran',
            sortable: true,
            style: {
                fontWeight: 600
            },
            selector: (row: any) => `Rp. ${useCurrency(row.total_request)}`
        },
        {
            name: 'Status',
            sortable: true,
            minWidth: '180px',
            selector: (row: any) => <Badge success rounded>{row.status}</Badge>
        }
    ];

    return (
        <>
            <TabLink tabActive="menunggu-konfirmasi" />
            <div className="container-fluid px-3 py-2 px-md-4 py-md-3">
                <ListPaymentTable>
                    {!loading && list.length > 0 && (
                        <Table
                            from={itemOffset + 1}
                            to={endOffset > totalList ? totalList : endOffset}
                            selectable
                            clearSelectedRows={toggleCleared}
                            totalList={totalList}
                            pageCount={totalPage}
                            loading={loading}
                            paginate
                            columns={columns}
                            data={list}
                            onSelectedRowsChange={handleRowSelected}
                            onRowClicked={handleRowClick}
                            onPageChange={(e: any) => {
                                setCurrentPage(e.selected + 1);
                                const newOffset = (e.selected * itemsPerPage) % totalList;
                                setItemOffset(newOffset);
                            }}
                        />
                    )}
                    {!loading && list.length < 1 && (
                        <NoData message="Tidak ada data menunggu konfirmasi" />
                    )}
                </ListPaymentTable>
            </div>
            <FloatingPaymentSelected visible={selectedRows.length > 0}>
                <div className="row align-items-center px-3">
                    <div className="col-9">
                        <div className="row align-items-center">
                            <div className={`col-profile ${selectedRows.length > 1 ? 'stacking' : ''}`}>
                                {selectedRows.map((item: any, idx: any) => (
                                    <React.Fragment key={item.id}>
                                        {item.pm.image && (
                                            <div className="profile-img" style={{ left: `${idx !== 0 ? `-${idx * 10}` : '0'}px` }}>
                                                <img src={item.pm.image} width="35" height="35" alt={item.pm.name} />
                                            </div>
                                        )}
                                        {!item.pm.image && (
                                            <div className="profile-img" style={{ left: `${idx !== 0 ? `-${idx * 10}` : '0'}px` }}>
                                                <img src={noImage} width="35" height="35" alt={item.pm.name} />
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                            <div className="col-6">
                                <p className="mb-0">Total transaksi yang dipilih</p>
                                <p className="mb-0 font-bold font-large">{selectedRows.length}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <Button block rounded gradient onClick={() => setShowDrawerPayment(true)}>
                            Lanjutkan
                            <Icon icon="chevron-right" />
                        </Button>
                    </div>
                </div>
            </FloatingPaymentSelected>
            {showDrawerPayment && (
                <DrawerPaymentAction data={selectedRows} onDeleteData={() => handleRowDelete()} drawerHandler={(showDrawer: boolean) => setShowDrawerPayment(showDrawer)} />
            )}
        </>
    );
};

export default PaymentPending;
