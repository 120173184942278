/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Styled';
import useDate from '../../../helpers/useDate';
import { PhotoWrapper } from '../../../components/TabDrawer/ListBlacklist';
import BlacklistRecoverDialog from '../../../components/BlacklistRecoverDialog';

const BlacklistItemWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 5px 1rem 10px;
    margin: 20px 0 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 16px rgba(146, 146, 146, 0.25);
    color: var(--color-base-text-light);
`;

const BlacklistItemHeader = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 5px 0px 10px;
    border-bottom: 2px dashed #959598;
`;

const BlacklistItemBody = styled.div`
    position: relative;
    padding: 15px 0 0;
`;

interface IBlacklistItem {
    data: any
}

const BlacklistItem: React.FC<IBlacklistItem> = (props) => {
    const { data } = props;
    const [isRecover, setIsRecover] = useState<boolean>(false);

    const handleRecover = () => {
        setIsRecover(true);
    };

    return (
        <BlacklistItemWrapper>
            <BlacklistItemHeader>
                <div className="row">
                    <div className="col-6">{useDate.getMomentDate(data.created_at, true)}</div>
                    <div className="col-6 text-end">{`KG${data.id}`}</div>
                </div>
            </BlacklistItemHeader>
            <BlacklistItemBody>
                <div className="row align-items-center">
                    <div className="col-3">
                        <p className="m-0 font-bold">{data.name}</p>
                        <span className="font-small">{data.role}</span>
                    </div>
                    <div className="col-3">
                        <p className="m-0 font-small">Alasan Blacklist</p>
                        <p className="m-0">{data.reason}</p>
                    </div>
                    <div className="col-6">
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                {data.photo?.length > 0 && (
                                    <div>
                                        <p className="mb-1 font-small">Foto</p>
                                        <div className="d-flex">
                                            {data.photo.map(({ photo_url }: any) => (
                                                <PhotoWrapper key={photo_url} className="d-flex align-items-center justify-content-center overflow-hidden rounded me-2">
                                                    <img src={photo_url} alt="attachment" width="100%" />
                                                </PhotoWrapper>
                                            ))}
                                        </div>
                                        {data.photo?.length > 5 && (
                                            <p className="m-0 fw-semibold color-cs-primary cursor-pointer">Lihat lebih banyak</p>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div>
                                {data.recover_at && <p className="color-gray-primary mb-1">Dipulihkan</p>}
                                {data.recover_at ? <p className="m-0 fw-bold">{useDate.formatDate(data.recover_at)}</p> : (data.blacklist_by ? <Button onClick={handleRecover} rounded gradient>Pulihkan</Button> : '')}
                            </div>
                        </div>
                    </div>
                </div>

                {isRecover && <BlacklistRecoverDialog dialogHandler={(showDialog: boolean) => setIsRecover(showDialog)} blacklist_id={data.id} /> }
            </BlacklistItemBody>
        </BlacklistItemWrapper>
    );
};

export default BlacklistItem;
